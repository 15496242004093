import { RefinementListProps, useClearRefinements, useRefinementList } from "react-instantsearch";
import { Box, MultiSelect } from "sparta";

interface RefinementMultiSelectProps extends RefinementListProps {
  leftIcon?: React.ReactNode;
}

export function RefinementMultiSelect({ leftIcon, ...props }: RefinementMultiSelectProps) {
  const { items, refine, searchForItems } = useRefinementList(props);
  const { refine: clear } = useClearRefinements({
    includedAttributes: [props.attribute],
  });

  return (
    <Box width="min-content">
      <MultiSelect
        options={items}
        selectedValues={items.filter((i) => i.isRefined)}
        onOptionToggle={(value) => refine(value)}
        onSearchChange={searchForItems}
        onClear={clear}
        placeholder={props.searchablePlaceholder}
        shouldFilter={false}
        leftIcon={leftIcon}
      />
    </Box>
  );
}
